import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import React from "react";

interface FormControlProps {
  selectedAnswer: string;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
}

const CommonFormControl: React.FC<FormControlProps> = ({
  selectedAnswer,
  optionA,
  optionB,
  optionC,
  optionD,
}) => {
  return (
    <FormControl>
      <RadioGroup
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 15,
            borderRadius: 0,
          },
        }}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        defaultValue={selectedAnswer == "" ? null : selectedAnswer}
        // onChange={(e) => onChangeEvent(e, item.id)}
      >
        <FormControlLabel value="A" control={<Radio />} label={optionA} />
        <FormControlLabel value="B" control={<Radio />} label={optionB} />
        <FormControlLabel value="C" control={<Radio />} label={optionC} />
        <FormControlLabel value="D" control={<Radio />} label={optionD} />
      </RadioGroup>
    </FormControl>
  );
};

export default CommonFormControl;
