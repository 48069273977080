import React from "react";
import { experimentalStyled, styled } from "@material-ui/core/styles";
import { Box, Button, Container, Paper, Typography } from "@material-ui/core";

const MainContainer = experimentalStyled(Box)(
  ({ theme }) => `
  display: flex;
  min-height: 100vh;
  // background: red;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
@media (max-width: ${theme.breakpoints.values.md}px) {
  padding: 0;
}
*{
margin: 1rem 0;
}
          `
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const InnerContainer = experimentalStyled(Container)(
  ({ theme }) => `
            
            @media (max-width: ${theme.breakpoints.values.md}px) {
                width: 100vw;
        }
            `
);

const ExamEnd = () => {
  return (
    <MainContainer>
      <InnerContainer maxWidth="sm">
        <Item>Exam Already Ended</Item>
        <Typography align="center">
          For more Exams you can visit here. Get unlimited exams at affordable
          price and increase your chance to get MBBS.
        </Typography>
      </InnerContainer>
      {/* <Button>{new Date(date).toUTCString()}</Button> */}
    </MainContainer>
  );
};

export default ExamEnd;
