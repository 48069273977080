import axios from "axios";
import { configuration } from "../config";

const api = axios.create({
  baseURL: configuration.apiUrl,
});

api.defaults.headers.post["Content-Type"] = "application/json";

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  //  console.log(token)
  if (!token) window.location.href = "/";
  config.headers!.Authorization = `Bearer ${token}`;
  // console.log(config)
  return config;
  
});

api.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      // console.log(response.data)
      return Promise.resolve(response.data);
    }
  },
  async (error) => {
    if (error && error.message === "Network Error") {
      // window.location.href = '/500';
    }
    const { response, request } = error;
    if (response) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (response.status >= 400 && response.status < 500) {
        return Promise.reject(response.data);
      }
    } else if (request) {
      return null;
    }
    return Promise.reject(error);
  }
);

export { api };
