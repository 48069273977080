import React from "react";
import { experimentalStyled, styled } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  Typography,
  Alert,
  Paper,
} from "@material-ui/core";

const MainContainer = experimentalStyled(Box)(
  ({ theme }) => `
            display: flex;
            min-height: 100vh;
            // background: red;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
          @media (max-width: ${theme.breakpoints.values.md}px) {
            padding: 0;
      }
      *{
          margin: 1rem 0;
      }
          `
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const InnerContainer = experimentalStyled(Container)(
  ({ theme }) => `
            
            @media (max-width: ${theme.breakpoints.values.md}px) {
                width: 100vw;
        }
            `
);

const NotStarted = ({ date }: { date: number }) => {
  return (
    <MainContainer>
      {/* <img src="https://medprepnepal.s3.ap-southeast-1.amazonaws.com/questions/1648053338552.png" /> */}
      {/* <Box>
        <img src="https://medprepnepal.s3.ap-southeast-1.amazonaws.com/questions/1648053414981.png" />
      </Box> */}
      <InnerContainer maxWidth="sm">
        <Item>Exam Starts In {new Date(date).toLocaleString()}</Item>

        {/* <Typography variant="h4" align="center">
          Sorry Exam Hasn't Started Yet
        </Typography> */}
        <Typography align="center">
          At this moment you can visit see our package. At this moment you can
          visit see our package.At this moment you can visit see our package.At
          this moment you can visit see our package.At this moment you can visit
          see our package.
        </Typography>

        {/* <Typography variant="h5">
          Exam Starts On : {new Date(date).toUTCString()}
        </Typography> */}
        {/* <Button>{new Date(date).toUTCString()}</Button> */}
      </InnerContainer>
    </MainContainer>
  );
};

export default NotStarted;
