import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Popover,
  Card,
  CircularProgress,
  Radio,
  RadioGroup,
  Typography,
  Modal,
} from "@material-ui/core";
import queryString from "query-string";
import { useLocation, useSearchParams } from "react-router-dom";
import Countdown from "react-countdown";
import Timer from "react-compound-timer";
import moment from "moment-timezone";
import { experimentalStyled, styled } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { api } from "../http/api";
import toast from "react-hot-toast";
import NotStarted from "./NotStarted";
import ExamEnd from "./ExamEnd";
import { EmbeddedQuestionsAns, QuestionsAns } from "../Types";
import NormalQuestion from "../components/NormalQuestion";
import EmbeddedQuestion from "../components/EmbeddedQuestion";

const MainContainer = experimentalStyled(Box)(
  ({ theme }) => `
        padding: 0 10rem;
        // background: url("https://scontent.fktm1-1.fna.fbcdn.net/v/t39.30808-6/272908592_109714334961547_6331485129231379147_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=-yluoLQOy24AX-nullb&tn=LVTkPOaEaOiqnc16&_nc_ht=scontent.fktm1-1.fna&oh=00_AT-2kLe1OOGiPht8MX00NnxjF3IdWBRowZMguKb_lJpiPw&oe=62276A87") no-repeat fixed center;
        
        min-height: 100vh;
        // max-width: 100vw;
        @media (max-width: ${theme.breakpoints.values.md}px) {
          padding: 0;
    }
        `
);

const HeaderCard = experimentalStyled(Card)(
  ({ theme }) => `
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
    }
        `
);

const ExamName = experimentalStyled(Typography)(
  ({ theme }) => `
  font-weight: 500;
  font-size: 1.6rem;
    }
        `
);
const PoweredByContainer = experimentalStyled(Box)(
  ({ theme }) => `
  position: fixed;
  bottom: 10px;
  z-index: 1;
  right: 10px;
  display: block;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: none;
}
        `
);

const PoweredByText = experimentalStyled(Typography)(
  ({ theme }) => `
  font-weight:bold; opacity: 0.5; font-size: 14px
    }
        `
);

const notify = (message: String) => toast(message as unknown as any);

const serverTime = Date.now();

const kathmanduTime = moment.tz(serverTime, "Asia/Kathmandu").format();
const kathmanduTimeInNumber = new Date(kathmanduTime).getTime();

const Exam: React.FC = (): JSX.Element => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [questionAns, setquestionAns] = useState<QuestionsAns[]>([]);
  const [embeddedquestionAns, setembeddedquestionAns] = useState<
    EmbeddedQuestionsAns[]
  >([]);
  const [resultId, setresultId] = useState("");

  const [startDate, setstartDate] = useState<number>(kathmanduTimeInNumber);
  const [endDate, setendDate] = useState<number>(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [examFinishModal, setexamFinishModal] = useState(false);

  let navigate = useNavigate();

  const getQuestions = async () => {
    const response = await api.get("/web/exam");
    console.log(response);

    setresultId(response.data.data.id);
    setembeddedquestionAns(response.data.data.embeddedQuestionAns);
    setquestionAns(response.data.data.questionAns);
    setstartDate(response.data.startDate);
    setendDate(response.data.endDate);
  };

  useEffect(() => {
    const token = searchParams.get("token");

    token ? localStorage.setItem("token", token) : (window.location.href = "/");

    getQuestions();
  }, []);

  const onChangeEvent = async (e: any, question: any) => {
    const token = searchParams.get("token");
    const response = await api.patch(`/web/${resultId}`, {
      selectedAnswer: e.target.value,
      question,
    });

    setstartDate(response.data.startDate);
    setendDate(response.data.endDate);
  };

  const endExam = async () => {
    setDisplayModal(false);
    try {
      notify("Submitting your exam");

      const token = searchParams.get("token");
      await api.patch(`/app/results/${resultId}`, { isCompleted: true });
      notify("Exam submitted successfully");

      navigate("/exam_success");
    } catch (e) {
      notify("Failed to submit");
    }
  };

  const getResult = async () => {
    try {
      await api.post(`/results/pdf/${resultId}`);
      alert("Pdf Sent to email");
    } catch (e) {
      alert("Failed to send email");
    }
  };

  const confirmSubmit = () => {
    setDisplayModal(!displayModal);
  };
  if (endDate < Date.now()) {
    setTimeout(() => {
      setexamFinishModal(true);
    }, Math.floor(endDate - Date.now()));
  }
  // return <NotStarted date={startDate} />;
  // return <ExamEnd />
  if (startDate === 0 || endDate === 0) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <h4>Please wait...</h4>
      </Box>
    );
  } else {
    if (startDate > serverTime) {
      return <NotStarted date={startDate} />;
    }
    if (endDate < serverTime) {
      return <ExamEnd />;
    }

    return (
      <>
        <MainContainer>
          <HeaderCard>
            <Box>
              <ExamName>Medprep Exam</ExamName>
              <Timer
                initialTime={endDate - kathmanduTimeInNumber}
                direction="backward"
              >
                {() => (
                  <div>
                    <span>Time: </span>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <Timer.Days /> d: <Timer.Hours />h : <Timer.Minutes /> m :{" "}
                      <Timer.Seconds /> s
                    </span>
                  </div>
                )}
              </Timer>
            </Box>
            <Button variant="contained" onClick={confirmSubmit}>
              Submit
            </Button>
          </HeaderCard>
          <PoweredByContainer maxWidth="xl">
            <PoweredByText>
              Powered by <br /> BitpointX
            </PoweredByText>
          </PoweredByContainer>
          {questionAns.length > 0 &&
            questionAns.map((item, index) => {
              return <NormalQuestion item={item} key={index} index={index} />;
            })}
          <h4>Embedded Question</h4>
          {embeddedquestionAns.length > 0 &&
            embeddedquestionAns.map((item, index) => {
              return (
                <EmbeddedQuestion
                  item={item}
                  index={index}
                  key={index}
                  normalQuestionLength={questionAns.length}
                />
              );
            })}

          <Dialog open={displayModal}>
            <DialogTitle>Do you really want to submit?</DialogTitle>
            <DialogContent>
              <Button onClick={endExam}>Yes</Button>
              <Button onClick={() => setDisplayModal(false)}>No</Button>
            </DialogContent>
          </Dialog>

          <Dialog open={examFinishModal}>
            <DialogTitle>Exam Time Over.</DialogTitle>
            <DialogContent>
              <Button variant="contained">Submit</Button>
            </DialogContent>
          </Dialog>
        </MainContainer>
      </>
    );
  }
};

export default Exam;
