import {
  Box,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";

import parse from "html-react-parser";
import { EmbeddedQuestionsAns } from "../Types";
import CommonFormControl from "./Global/FormControl";
import { QuestionCard, QuestionNo } from "./Global/styled-components";
import { experimentalStyled, styled } from "@material-ui/core/styles";

const SubQuestionMainContainer = experimentalStyled(Box)(
  ({ theme }) => `
          padding:0 2rem;
      }
          `
);

const SubQuestion = experimentalStyled(Box)(
  ({ theme }) => `
            padding:1rem 0; 
        }
            `
);
interface EmbeddedQuestionProps {
  item: EmbeddedQuestionsAns;
  index: number;
  normalQuestionLength: number;
}

const EmbeddedQuestion: React.FC<EmbeddedQuestionProps> = ({
  item,
  index,
  normalQuestionLength,
}) => {
  return (
    <QuestionCard>
      <QuestionNo>
        <span>{normalQuestionLength + index + 1}.</span>
        <span>{parse(item.question.question)}</span>
      </QuestionNo>
      <SubQuestionMainContainer>
        {item.questionAns.map((eachItem, subIndex) => {
          return (
            <SubQuestion key={subIndex} py={2}>
              <QuestionNo style={{ display: "flex" }}>
                <span>{subIndex + 1}.</span>
                <span>{parse(eachItem.question.question)}</span>
              </QuestionNo>
              <Container maxWidth="xl">
                <CommonFormControl
                  selectedAnswer={eachItem.selectedAnswer}
                  optionA={eachItem.question.optionA}
                  optionB={eachItem.question.optionB}
                  optionC={eachItem.question.optionC}
                  optionD={eachItem.question.optionD}
                />
              </Container>
            </SubQuestion>
          );
        })}
      </SubQuestionMainContainer>
    </QuestionCard>
  );
};

export default EmbeddedQuestion;
