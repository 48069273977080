import { Card } from "@material-ui/core";
import { experimentalStyled, styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/system";

export const QuestionCard = experimentalStyled(Card)(
  ({ theme }) => `
    margin: 10px 0;
    border-radius: 10px;
    padding: 1rem ;
    box-shadow: 0;
    //       @media (max-width: ${theme.breakpoints.values.md}px) {
    //         padding: 0;
    //   }
          `
);

export const QuestionNo = experimentalStyled(Box)(
  ({ theme }) => `
   display: flex;
          `
);
