import {
  Card,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
} from "@material-ui/core";
import React from "react";
import { QuestionsAns } from "../Types";
import CommonFormControl from "./Global/FormControl";

import parse from "html-react-parser";
import { QuestionCard, QuestionNo } from "./Global/styled-components";
import { QuestionAnswer } from "@material-ui/icons";

interface NormalQuestionProps {
  item: QuestionsAns;
  index: number;
}

const NormalQuestion: React.FC<NormalQuestionProps> = ({
  item,
  index,
}): JSX.Element => {
  return (
    <QuestionCard>
      <QuestionNo>
        <span>{index + 1}.</span>
        <span>{parse(item.question.question)}</span>
      </QuestionNo>
      <Container maxWidth="xl">
        <CommonFormControl
          selectedAnswer={item.selectedAnswer}
          optionA={item.question.optionA}
          optionB={item.question.optionB}
          optionC={item.question.optionC}
          optionD={item.question.optionD}
        />
      </Container>
    </QuestionCard>
  );
};

export default NormalQuestion;
