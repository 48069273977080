import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import { experimentalStyled, styled } from "@material-ui/core/styles";

const MainContainer = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        // padding: 50px;
        alignItem: center;
    // background: url("https://scontent.fktm1-1.fna.fbcdn.net/v/t39.30808-6/272908592_109714334961547_6331485129231379147_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=-yluoLQOy24AX-nullb&tn=LVTkPOaEaOiqnc16&_nc_ht=scontent.fktm1-1.fna&oh=00_AT-2kLe1OOGiPht8MX00NnxjF3IdWBRowZMguKb_lJpiPw&oe=62276A87") no-repeat fixed center;
          
        //   min-height: 100vh;
        //   // max-width: 100vw;
        //   @media (max-width: ${theme.breakpoints.values.md}px) {
        //     padding: 0;
      }
          `
);

const Thank = () => {
  return (
    <MainContainer>
      <Typography variant="h5" >Thank you for attending Exam</Typography>
      <Typography>Result 'ill be published shortly</Typography>
    </MainContainer>
  );
};

export default Thank;
