import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Navigate } from "react-router-dom";
import Exam from "./screens/Exam";
import Home from "./screens/Home";
import toast, { Toaster } from "react-hot-toast";
import Thank from "./screens/Thank";
import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: ['"M PLUS 1"', "sans-serif"].join(","),

    // fontFamily: ['"Montserrat"', 'Open Sans'].join(',')
  },
});

const App = () => {
  // const user = localStorage.getItem("user");

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/web" element={<Exam />} />
          <Route path="/exam_success" element={<Thank />} />
        </Routes>
      </Router>
      <Toaster />
    </ThemeProvider>
  );
};

export default App;
